import React from 'react';

class AreaAtuacao extends React.Component {
    render() {
        return (
            <section id="areaatuacao">
                <div class="row">
                    <div class="col-6 p-0">
                        <iframe title="César Peghini" width="100%" height="100%"
                            src="https://www.youtube.com/embed/ZuSG_56JdHs"
                            frameborder="0" allow="autoplay; encrypted-media"
                            allowfullscreen></iframe>
                    </div>
                    <div class="col-6 bg-gray line-height">
                        <h3 class="text-uppercase mt-3">Atuações</h3>
                        <hr />
                        <p>- Gestão Financeira<br />
                            - Gestão de Recursos Humanos<br />
                            - Gestão de Arquivos e Documentos <br />
                            - Gestão de Cobrança Amigável e Inadimplência<br />
                            - Atendimento Personalizado em Assembleias<br />
                            - Apoio ao Síndico para contratação de Serviços <br />
                            - Prestação de Contas<br />
                            - Gestão de Controle, Manutenção e Vistorias Técnicas<br />
                            - Programas de Sustentabilidade<br />
                            - Portal Web
 
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}

export default AreaAtuacao;
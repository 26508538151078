import React from "react";
import logo from "./assets/logo.png";
import "./App.css";

import Home from "./Home";
import QuemSomos from "./QuemSomos";
import AreaAtuacao from "./AreaAtuacao";
import Advogados from "./Advogados";
import Contatos from "./Contatos";

function App() {
  return (
    <div className="App">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <a class="navbar-brand" href="#">
            <img src={logo} />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a class="nav-link text-uppercase" href="#">
                  Home <span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-uppercase" href="#quemsomos">
                  Quem somos
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-uppercase" href="#areaatuacao">
                  Atuações
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-uppercase" href="#contatos">
                  Contatos
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text-uppercase"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://sistemacondominioonline.com.br/login/"
                >
                  Área do Condômino
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="container-fluid p-0">
        <Home />
        <QuemSomos />
        <AreaAtuacao />
        <Contatos />
      </div>

      <footer class="p-3">
        <div class="container">
          <div class="row ml-0">
            <div class="col-lg-12 col-sm-12 p-0 m-auto text-center">
              <img alt="Peghini - Governança Condominial" src={logo} />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;

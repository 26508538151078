import React from 'react';

class Advogados extends React.Component {
    render() {
        return (
            <section id="advogados">
                <div class="container">
                    <div class="row m-0">
                        <div class="col-12">
                            <h3 class="text-uppercase mt-3">Advogados</h3>
                            <hr />
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-6 bg-gray">
                            <p><strong>Cesar Calo Peghini</strong><br />
                                Doutor em Direito Civil pela PUC/SP. Mestre em Função Social do Direito pela Faculdade Autônoma de Direito FADISP. Especialista em Direito do Consumidor na experiência do Tribunal de Justiça da União Européia e na Jurisprudência Espanhola, pela Universidade de Castilla-La Mancha, Toledo/ES. Especialista em Direito Civil pela Instituição Toledo de Ensino ITE. Especialista em Direito Civil e Processo Civil pela Escola Paulista de Direito - EPD. Graduado em Direito pelo Centro Universitário das Faculdades Metropolitanas Unidas - FMU. Professor da Rede de Ensino Luis Flávio Gomes - LFG; Professor da Escola Paulista de Direito - EPD; Professor da pós-graduação do Centro Universitário Mackenzie; Professor visitante em cursos de pós-graduação lato sensu. </p>
                        </div>
                        <div class="col-6 bg-gray">
                            <p><strong>Aline Costa Peghini</strong><br />
                                Mestre em Direito pela Universidade Nove de Julho - Uninove. Especialista em Direito do Consumidor na experiência do Tribunal de Justiça da União Européia e na Jurisprudência Espanhola, pela Universidade de Castilla-La Mancha, Toledo/ES. Especialista em Direito Civil e Processo Civil pela Escola Paulista de Direito - EPD. Graduada em Direito pelo Centro Universitário das Faculdades Metropolitanas Unidas - FMU.
                        </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Advogados;
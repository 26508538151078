import React from 'react';

class QuemSomos extends React.Component {
    render() {
        return (
            <section id="quemsomos">
                <div class="container">
                    <h3 class="text-uppercase">Quem somos</h3>

                    <hr />

                    <p>A Peghini Governança Condominial é uma empresa especializada na área de gestão de condomínios com profissionais qualificados e experiência nas áreas administrativa, financeira, contabilidade, recursos humanos, jurídica, entre outras.</p>

                    <p>Sua atuação tem como elemento fundamental o serviço na modalidade <i>premium</i> realizada de forma personalizada e individual a qual visa atender condôminos e síndicos em soluções estratégicas de governança.</p>

                    <p>Dessa forma, observado a legislação pertinente e o baixo custo operacional dos ativos condominiais o serviço prestado é realizado de forma transparente, com análise pormenorizada das necessidades e características próprias de seu Condomínio com intuito de atingir alto padrão de qualidade.</p>

                    <p>Como característica máxima da Peghini Governança Condominial é a solução estratégica de governança.</p>
                </div>
            </section>
        )
    }
}

export default QuemSomos;
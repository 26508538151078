import React from 'react';
import meeting from './assets/meeting.jpg'

class Home extends React.Component {
    render() {
        return (
            <section id="home">
                <img alt="São Paulo" class="img-fluid" src={meeting} />
            </section>
        )
    }
}

export default Home;
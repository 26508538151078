import React from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "auto"
};

class Contatos extends React.Component {
  state = {
    showingInfoWindow: false, //Hides or the shows the infoWindow
    activeMarker: {}, //Shows the active marker upon click
    selectedPlace: {} //Shows the infoWindow to the selected place upon a marker
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };
  render() {
    return (
      <section id="contatos">
        <div class="container">
          <div class="row m-0">
            <div class="col-6">
              <h3 class="text-uppercase">Nossos Contatos</h3>

              <hr />

              <small>
                <i class="fa fa-home"></i> Sede: São Paulo -SP <br />
                Avenida Eng. Caetano Álvares, 530, Casa Verde <br />
                <i class="fa fa-phone"></i> Tel. 11 2369-5777 / 11 3855-2490<br />
                <br />
                Horário de funcionamento:
                <br /> Segunda-feira à Sexta-feira: 09h00 - 17h00
                <br />
                <br />
                <i class="fa fa-envelope"></i>{" "}
                <a href="mailto:contato@peghini.com.br">
                  contato@peghini.com.br
                </a>
                <br />
                <a
                  href="https://www.youtube.com/channel/UCxNPtyYCZY-DPV0EDXjsWZg"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="icon"
                  title="youtube"
                >
                  <i class="fab fa-youtube" aria-hidden="true"></i>
                </a>
                <a
                  href="https://www.instagram.com/peghini_governanca_condominal/"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="icon"
                  title="instagram"
                >
                 <i class="fab fa-instagram" aria-hidden="true"></i>
                </a>
              </small>
            </div>
            <div class="col-6">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    width="100%"
                    height="279"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Avenida%20Eng.%20Caetano%20%C3%81lvares%2C%20530%2C%20Casa%20Verde%20&t=&z=16&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCJG4QUmngOaG-ImjvR8yGiB80O2e9yKDs"
})(Contatos);
